import React, { useState , useEffect, useCallback , useRef , useContext} from 'react'
import { useSelector , useDispatch , Provider} from 'react-redux';
import moment from "moment";
import Page from '../../Component/All/Page'
import Datep from '../../Component/All/Date'
import Spinner from '../Spinner/Spinner';
import ReplyIcon from '@mui/icons-material/Reply';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { FormattedMessage , useIntl } from "react-intl";

import Swal from 'sweetalert2'

import DatePicker, { registerLocale } from "react-datepicker";
import { subDays , addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import zhTW from "date-fns/locale/zh-TW"; 
import { ErrorContext } from '../../Routes';

registerLocale("zhTW", zhTW); 

var currentDate = new Date();
currentDate.setDate(currentDate.getDate());
currentDate.setHours(0, 0, 0, 0);

var endToDate = new Date();
endToDate.setHours(23, 59, 59, 999);
const ListTransactions = ({setBoxSwitch}) => {
  const intl = useIntl();
  const { TimeMoment } = useContext(ErrorContext);

  const [loading , setLoading] = useState(true)
  const [perpage] = useState(15)
  const [nowPage, setNowPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endToDate);
  const [firstLoading , setFirstLoading] = useState(true)
  const startRef = useRef()
  const endRef = useRef()
  //redux
  const dispatch = useDispatch();
  const listTransactions = useSelector(state => state.betHistory.listTransactions)
  const searchHandler = ( nowPage = 1 , perPage = 15) => {
    // event.preventDefault();
    //第一次loading讀取當天列表
    if(firstLoading){
        const startStamp = 0;
        const endStamp = 0;
        dispatch({ type: 'SAGA_FETCH_ISTTRANSACTIONS_API' , nowPage , perpage , startStamp , endStamp , setLoading , setTotalPage});
        setFirstLoading(false)
    } else{
        const encodedStartTime = moment(startRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const encodedEndTime = moment(endRef.current.props.selected).format('YYYY-MM-DD HH:mm:ss');
        const startStamp = parseInt(`${moment(encodedStartTime, 'YYYY-MM-DD HH:mm:ss').unix()}000`);
        const endStamp = parseInt(`${moment(encodedEndTime, 'YYYY-MM-DD HH:mm:ss').unix()}999`);
        dispatch({ type: 'SAGA_FETCH_ISTTRANSACTIONS_API' , nowPage , perpage , startStamp , endStamp , setLoading , setTotalPage});
        
    }
  };
  useEffect(() => {
    searchHandler(nowPage,perpage)
  }, [nowPage,perpage]);

  const typeSel = [
    // {
    //   name : '請選擇',
    //   value : 0
    // },
    {
      // name : '上分(儲值)',
      name: intl.formatMessage({
        id: 'nav.charge',
      }),
      value : 1
    },
    {
      // name : '下分(提款)',
      name: intl.formatMessage({
        id: 'profile.withdraw',
      }),
      value : 2
    },
    {
      // name : '下注',
      name: intl.formatMessage({
        id: 'typeSel.bet',
      }),
      value : 3
    },
    {
      // name : '下注輸贏',
      name: intl.formatMessage({
        id: 'typeSel.betWL',
      }),
      value : 4
    },
    {
      // name : '全民代理',
      name: intl.formatMessage({
        id: 'typeSel.nationalAgent',
      }),
      value : 5
    },
    {
      // name : '手動上分',
      name: intl.formatMessage({
        id: 'typeSel.ManuallyUp',
      }),
      value : 6
    },
    {
      // name : '手動下分',
      name: intl.formatMessage({
        id: 'typeSel.ManuallyDown',
      }),
      value : 7
    },
    {
      // name : '活動',
      name: intl.formatMessage({
        id: 'typeSel.activity',
      }),
      value : 8
    },
    {
      // name : '下注退款',
      name: intl.formatMessage({
        id: 'typeSel.betRefund',
      }),
      value : 9
    },
    {
      // name : '注單活動獎勵',
      name: intl.formatMessage({
        id: 'typeSel.betActivity',
      }),
      value : 10
    },
    {
      // name : '注單活動獎勵退款',
      name: intl.formatMessage({
        id: 'typeSel.betActivityRefund',
      }),
      value : 11
    },
    {
      name : 'Jackpot',
      value : 12
    },
    {
      // name : '交易員收益',
      name: intl.formatMessage({
        id: 'typeSel.traderProfit',
      }),
      value : 13
    },
    {
      // name : '代理返水',
      name: intl.formatMessage({
        id: 'typeSel.agentRebate',
      }),
      value : 14
    },
    {
      name: 'Promo',
      value : 15
    },
    {
      // name: '入款（返款）',
      name: intl.formatMessage({
        id: 'typeSel.deposit',
      }),
      value : 16
    },
    {
      // name: '出款（返款）',
      name: intl.formatMessage({
        id: 'typeSel.Withdraw',
      }),
      value : 17
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.NowRebate',
      }),
      value : 18
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.TranThird',
      }),
      value : 19
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.TranBalanceThird',
      }),
      value : 20
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.CancelUnivAgent',
      }),
      value : 21
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.CancelRebate',
      }),
      value : 22
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.CancelReal',
      }),
      value : 23
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.Manincrease',
      }),
      value : 24
    },
    {
      // name: '注單實時返水',
      name: intl.formatMessage({
        id: 'typeSel.ManLimit',
      }),
      value : 25
    },
  ]
  
//   const judgeField = (player_bet , num) =>{
//     if(num === 1){
//         var element = field1.find(item => item.value === player_bet.split(',').slice(0, 2)[0]);
//         return element ? element.name : player_bet.split(',').slice(0, 2)[0];
//     } else if (num ===2){
//         var element = field2.find(item => item.value === player_bet.split(',').slice(0, 2)[1]);
//         return element ? element.name : player_bet.split(',').slice(0, 2)[1];
//     }
//   }


  return (
    <div className="bettingRecordWrap listTransactionsWrap bottWrap">
        <div className='titleFlex'>
            <button className='backBtn' onClick={()=>{
                setBoxSwitch(false)
            }}
            ><ReplyIcon/></button>
            
            <div>
              <FormattedMessage
                    id={`profile.listTransactions`}
              ></FormattedMessage>
            </div>
        </div>
        <div className='dataFlex'>
            <div className='timeTitle'>
                <FormattedMessage
                    id={`game.time`}
                ></FormattedMessage>
            </div>
            <DateRangeIcon/>
        </div>
        <Datep
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        startRef={startRef}
        endRef={endRef}
        />
        <button className='searchBtn' 
            onClick={() => {
                    if (startDate && endDate) {
                        searchHandler(1,15);
                        setNowPage(1);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text:intl.formatMessage({
                              id: 'alert.notSelected',
                            }),
                            showConfirmButton: true,
                        });
                    }
                }}>
                 <FormattedMessage
                    id={`profile.inquire`}
                ></FormattedMessage>
        </button>
        <div className='listBx'>
            {loading === false ? 
                listTransactions.data.pagination.total === 0 ? 
                <div className='notData'>
                   <FormattedMessage
                        id={`profile.noDataFoundToday`}
                    ></FormattedMessage>
                </div> :
                    <div>
                        <div className='scrollBx'>
                            <div className='titileBx'>
                                <div>
                                  <FormattedMessage
                                      id={`game.type`}
                                  ></FormattedMessage>
                                </div>
                                <div>
                                  <FormattedMessage
                                      id={`profile.amount`}
                                  ></FormattedMessage>
                                </div>
                                <div>
                                  <FormattedMessage
                                      id={`MemberWrap.balance`}
                                  ></FormattedMessage>
                                </div>
                                <div>
                                  <FormattedMessage
                                      id={`game.time`}
                                  ></FormattedMessage>
                                </div>
                            </div>
                            {  
                                <ul>
                                    {
                                        listTransactions.data.response.map((v)=>{ 
                                            return (
                                                <li key={v.id}>
                                                     <div>
                                                      {typeSel.find(element =>
                                                                element.value === v.type
                                                            ).name}
                                                      {
                                                        v.type == 19 && v.content
                                                      }
                                                      {
                                                        v.type == 20 && v.content
                                                      }
                                                    </div>
                                                     <div>{v.amount}</div>
                                                     <div>{v.balance}</div>
                                                     <div>{
                                                        TimeMoment(v.create_at)
                                                     }</div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            }
                        </div>
                        {totalPage > 1 &&
                        <Page
                            totalPage={totalPage}
                            setNowPage={setNowPage}
                            nowPage={nowPage}
                        />}
                    </div>
                : <Spinner/> 
            }
        </div>
    </div>
  )
}

export default ListTransactions