import React, { useState, useEffect , createContext } from "react";
import { BrowserRouter as Router, Routes, Route , Navigate , BrowserRouter , useLocation } from 'react-router-dom';
import { FormattedMessage, IntlProvider , useIntl } from "react-intl";
import './CSS/index.scss';
import Layout from "./Layout/Layout";
import moment from "moment";
import tz from "moment-timezone"
import RegisterPage from "./Pages/RegisterPage";
import LoginPage from "./Pages/LoginPage";
import UpdatePwdPage from './Pages/UpdatePwdPage'
import Home from "./Pages/Home";
import ProfilePage from "./Pages/Profile/ProfilePage";
import Profit from "./Pages/Profile/Profit";
import GameLayout from "./Layout/GameLayout";
import GamePage from "./Pages/Game/GamePage";
import FourBall from "./Pages/Game/FourBall";
import FourBallMa from "./Pages/Game/FourBallMa";
import MarkSix from "./Pages/Game/MarkSix";
import MarkSixMacao from "./Pages/Game/MarkSixMacao";
import Keno from "./Pages/Game/Keno.js";
import VietnamLottery from "./Pages/Game/VietnamLottery.js"
import BingoPage from "./Pages/Game/bingo.js";


// 交易員功能
import TradersPage from "./Pages/Trader/Traders";
import JoinTraderPage from "./Pages/Trader/JoinTrader";
import TraderHomePage from "./Pages/Trader/TraderHome";
import TraderMyHomePage from "./Pages/Trader/TraderMyHome";
import TraderSetPage from "./Pages/Trader/TraderSet";
import TrderRecordPage from "./Pages/Trader/TrderRecord.js";
import ActivityPage from "./Pages/Activity/ActivityPage.js";
import ActivityDetail from "./Pages/Activity/ActivityDetail.js";




import Swal from 'sweetalert2';

import useSound from 'use-sound';
import boopSfx from './sound/mouse_click.mp3';

// import {Howl, Howler} from 'howler';
// const intl = useIntl();
// export const ErrorMsg = (code, msg , who) => {
//   // const intl = useIntl();

//   // 在 ParentComponent 中调用 useIntl，然后将 intl 传递给 ErrorMsg
//   return <ErrorMsgIn code={code} msg={msg} who={who} />;
// };
// export const ErrorMsg = (code, msg , who) =>{
//   // const intl = useIntl();
//   ErrorMsgIn(code, msg , who)
// }

// export const ErrorMsg = (code, msg , who) => {
//   switch (code) {
//     case 400:
//       if (msg.includes("data_invalid")) {
        
//         if(msg.includes("data_invalid.bet_time")){
//           Swal.fire({
//             icon: "warning",
//             text: "已超過可下注時間",
//             // html: intl.formatMessage({
//             //   id: 'alert.betTimeout',
//             // }),
//             showConfirmButton: true,
//           });
//         } else if(msg.includes("api.data_invalid: request body")){
//             Swal.fire({
//               icon: "warning",
//               text: "未選擇完全",
//               // text : intl.formatMessage({
//               //   id: 'alert.notSelected',
//               // }),
//               showConfirmButton: true,
//           });
//         } else if(msg.includes("api.bad_request.bet_amount")){
//           Swal.fire({
//             icon: "warning",
//             text: "流水金額未達標準",
//             // text : intl.formatMessage({
//             //   id: 'alert.amountNot',
//             // }),
//             showConfirmButton: true,
//           });
//         } else if(msg.includes("api.data_invalid.verification_code")){
//           Swal.fire({
//             icon: "warning",
//             text: "驗證碼錯誤",
//             // text : intl.formatMessage({
//             //   id: 'alert.verifError',
//             // }),
//             showConfirmButton: true,
//           });
//         } else if(msg.includes("api.data_invalid.phone")){
//           Swal.fire({
//             icon: "warning",
//             text: "手機號碼格式錯誤",
//             // text : intl.formatMessage({
//             //   id: 'alert.wrongPhone',
//             // }),
//           //   text: <FormattedMessage
//           //     id={`profile.numberAlreadyExists`}
//           // ></FormattedMessage>,
//             showConfirmButton: true,
//           });
//         } else{
//           Swal.fire({
//             icon: "warning",
//             text: "格式錯誤、請檢查資料欄位",
//             // text : intl.formatMessage({
//             //   id: 'alert.formatError',
//             // }),
//             showConfirmButton: true,
//           });
//         }

//       } else if(msg.includes("api.data_existent.account")){
//           Swal.fire({
//             icon: "warning",
//             text: "帳號已存在",
//             // text : intl.formatMessage({
//             //   id: 'alert.accountAlready',
//             // }),
//             showConfirmButton: true,
//         });
//       } else if(msg.includes("api.bad_request") && who === 'getPayOrderLauncher'){
//           Swal.fire({
//             icon: "warning",
//             text: "請先驗證手機號碼",
//             // text : intl.formatMessage({
//             //   id: 'alert.verifPhone',
//             // }),
//             showConfirmButton: true,
//         });
//       } else if(msg.includes("api.bad_request")){
//           Swal.fire({
//             icon: "warning",
//             text: "下注失敗,請聯繫客服",
//             // text : intl.formatMessage({
//             //   id: 'alert.betError',
//             // }),
//             showConfirmButton: true,
//         });
//       } else if(msg.includes("api.not_found.platform_code")){
//         // if(msg.includes("api.not_found.platform_code")){
//           console.log(<FormattedMessage id="alert.accountIncorrect"/>)
//           Swal.fire({
//             icon: "warning",
//             // text: "帳號或密碼錯誤",
//             html :
//               <FormattedMessage
//                   id="alert.accountIncorrect"
//               ></FormattedMessage> 
//             ,
            
//             showConfirmButton: true,
//           });
//         // }
//       }
//       break;
//     case 401:
//       if (msg.includes('api.unauthorized')) {
//         Swal.fire({
//           icon: "warning",
//           text: "請重新登入操作",
//           // text : intl.formatMessage({
//           //   id: 'alert.logAgain',
//           // }),
//           showConfirmButton: true,
//         }).then((result) => {
//           if (result.isConfirmed) {
//             localStorage.removeItem("access_token");
//             window.location.href = "/";
//           }
//         });
//       }
//       break;
//     case 403:
//       if (msg.includes('api.forbidden')) {
//         Swal.fire({
//           icon: "warning",
//           text: "請重新登入操作，或此帳號尚無權限",
//           // text : intl.formatMessage({
//           //   id: 'alert.accountPer',
//           // }),
//           showConfirmButton: true,
//         });

//       }
//       break;
//     case 409:
//       if (msg.includes('data_existent') && who === 'setPhone') {
//         Swal.fire({
//           icon: "warning",
//           text: "已驗證",
//           // text : intl.formatMessage({
//           //   id: 'alert.verified',
//           // }),
//           showConfirmButton: true,
//         });
//       } else if (msg.includes('data_existent') && who === 'sendVerificationMsg'){
//         Swal.fire({
//           icon: "warning",
//           text: "該號碼已存在",
//           // text : intl.formatMessage({
//           //   id: 'alert.accountAlready',
//           // }),
//       //     
//           showConfirmButton: true,
//         });
//       }else{
//         Swal.fire({
//           icon: "warning",
//           text: "帳號已存在",
//           // text : intl.formatMessage({
//           //   id: 'alert.accountAlready',
//           // }),
//           showConfirmButton: true,
//         });
//       }
//       break;
//     case 500:
//       Swal.fire({
//         icon: "warning",
//         text: "伺服器錯誤請聯繫相關人員",
//         // text : intl.formatMessage({
//         //   id: 'alert.serverPer',
//         // }),
//         showConfirmButton: true,
//       });
//       break;
//     default:
//       break;
//   }
// };
// export const ErrorMsg = ({code, msg , who}) => {
//   // const intl = useIntl();
//   // return ErrorMsgIn(code, msg , who)
//   return <ErrorMsgIn code={code} msg={msg} who={who} />;
// };

// export const changLan = ({setLang , lang}) => {
//   return <select className="changLanSel" value={lang} onChange={(evt) => {
//       setLang(evt.target.value);
//       localStorage.setItem("language", evt.target.value);
//     }}>
//       <option value="cn">中文</option>
//       <option value="en">English</option>
//     </select>;
// };
export const ErrorContext = createContext();

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0); // 将页面滚动条位置重置到顶部
    };

    handleScrollToTop(); // 页面加载时执行一次

    const unlisten = () => {
      window.removeEventListener('hashchange', handleScrollToTop);
    };

    window.addEventListener('hashchange', handleScrollToTop);

    return () => {
      unlisten(); 
    };
  }, [location]); // 只在组件加载和卸载时执行一次

  return null; // ScrollToTop 组件不渲染任何内容
}

const RoutesBox = ({lang , setLang , setLocale}) => {
  const intl = useIntl();

  const [play] = useSound(boopSfx);
  const [isFirstPlaying, setFirstIsPlaying] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const [scrollDistance, setScrollDistance] = useState(0);
  const [lastPlayPosition, setLastPlayPosition] = useState(0);
  // 語系
//   const [lang, setLang] = useState("cn");
//   const [locale, setLocale] = useState("cn");
//   const numberFormatter = new Intl.NumberFormat('en');
//   useEffect(() => {
//     async function fetchData() {
//       const resp = await fetch(`./lang/${lang}.json`);
//       const data = await resp.json();
//       setLocale(data);
//     }
//     fetchData()
//     console.log(locale)
//   }, [lang]);


  useEffect(() => {
    if(!isFirstPlaying){
      setFirstIsPlaying(true)
    }{
      // let animationFrameId;

    

      // const handleScroll = () => {
      //   if (!isPlaying && scrollDistance - lastPlayPosition >= 150) {
      //     setIsPlaying(true);
      //     play();
      //     setLastPlayPosition(scrollDistance);

      //     animationFrameId = requestAnimationFrame(() => {
      //       setIsPlaying(false);
      //     });
      //   } else if (!isPlaying && lastPlayPosition - scrollDistance >= 150) {
      //     setLastPlayPosition(lastPlayPosition - 150);
      //     play();
      //   }
      // };

      // const handleScrollEvent = () => {
      //   setScrollDistance(window.pageYOffset);
      // };
  
      // window.addEventListener('scroll', handleScrollEvent);
  
      const handleClick = () => {
        play();
      };
  
      document.addEventListener('click', handleClick);

      // window.addEventListener('scroll', handleScroll);
  
      return () => {
        // window.removeEventListener('scroll', handleScrollEvent);
        document.removeEventListener('click', handleClick);
        // window.removeEventListener('scroll', handleScroll);
        // cancelAnimationFrame(animationFrameId);
      };
    }
   
  }, [play, isPlaying, scrollDistance, lastPlayPosition]); 
  
  useEffect(() => {
    setLang(localStorage.getItem("language"));
    async function fetchData() {
      const resp = await fetch(`./lang/${localStorage.getItem("language")}.json`);
      const data = await resp.json();
      setLocale(data);
    }
    console.log(localStorage.getItem("language"))
    fetchData()
  }, []);

  const ErrorMessage = (code, msg , who) => {
    switch (code) {
      case 400:
        if (msg.includes("data_invalid")) {
          
          if(msg.includes("data_invalid.bet_time")){
            Swal.fire({
              icon: "warning",
              // text: "已超過可下注時間",
              text:intl.formatMessage({
                id: 'alert.betTimeout',
              }),
              showConfirmButton: true,
            });
          } else if(msg.includes("api.data_invalid: request body")){
              Swal.fire({
                icon: "warning",
                // text: "未選擇完全",
                text:intl.formatMessage({
                  id: 'alert.notSelected',
                }),
                showConfirmButton: true,
            });
          }  else if(msg.includes("api.data_invalid.old_password") && who === 'password'){
            Swal.fire({
              icon: "warning",
              // text: "原密碼錯誤",
              text:intl.formatMessage({
                id: 'alert.oldPassword',
              }),
              showConfirmButton: true,
            });
          } else if(msg.includes("api.data_invalid.password") && who === 'password'){
            Swal.fire({
              icon: "warning",
              // text: "新密碼格式錯誤",
              text:intl.formatMessage({
                id: 'alert.password',
              }),
              showConfirmButton: true,
            });
          } else if(msg.includes("api.bad_request.bet_amount")){
            Swal.fire({
              icon: "warning",
              // text: "流水金額未達標準",
              text:intl.formatMessage({
                id: 'alert.amountNot',
              }),
              showConfirmButton: true,
            });
          } else if(msg.includes("api.data_invalid.verification_code")){
            Swal.fire({
              icon: "warning",
              // text: "驗證碼錯誤",
              text:intl.formatMessage({
                id: 'alert.verifError',
              }),
              showConfirmButton: true,
            });
          } else if(msg.includes("api.data_invalid.phone")){
            Swal.fire({
              icon: "warning",
              // text: "手機號碼格式錯誤",
              text:intl.formatMessage({
                id: 'alert.wrongPhone',
              }),
              showConfirmButton: true,
            });
          } else{
            Swal.fire({
              icon: "warning",
              // text: "格式錯誤、請檢查資料欄位",
              text:intl.formatMessage({
                id: 'alert.formatError',
              }),
              showConfirmButton: true,
            });
          }
  
        } else if(msg.includes("api.data_existent.account")){
            Swal.fire({
              icon: "warning",
              // text: "帳號已存在",
              text:intl.formatMessage({
                id: 'alert.accountAlready',
              }),
              showConfirmButton: true,
          });
        } else if(msg.includes("api.bad_request") && who === 'getPayOrderLauncher'){
            Swal.fire({
              icon: "warning",
              // text: "請先驗證手機號碼",
              text:intl.formatMessage({
                id: 'alert.verifPhone',
              }),
              showConfirmButton: true,
          });
        } else if(msg.includes("api.data_invalid.bet_amount")){
          Swal.fire({
            icon: "warning",
            // text: "下注金額未達最低金額",
            text : intl.formatMessage({
              id: 'alert.betAmount',
            }),
            showConfirmButton: true,
        });
        } else if(msg.includes("api.bad_request")){
            Swal.fire({
              icon: "warning",
              // text: "下注失敗,請聯繫客服",
              text : intl.formatMessage({
                id: 'alert.betError',
              }),
              showConfirmButton: true,
          });
        } else if(msg.includes("api.not_found.platform_code") && who === 'cs'){
          // if(msg.includes("api.not_found.platform_code")){
            console.log(<FormattedMessage id="alert.accountIncorrect"/>)
            Swal.fire({
              icon: "warning",
              // text: "帳號或密碼錯誤",
              text:intl.formatMessage({
                id: 'alert.platformCode',
              }),
              showConfirmButton: true,
            });
          // }
        }else if(msg.includes("api.not_found.platform_code")){
          // if(msg.includes("api.not_found.platform_code")){
            console.log(<FormattedMessage id="alert.accountIncorrect"/>)
            Swal.fire({
              icon: "warning",
              // text: "帳號或密碼錯誤",
              text:intl.formatMessage({
                id: 'alert.accountIncorrect',
              }),
              showConfirmButton: true,
            });
          // }
        }
        else if(msg.includes("api.data_invalid.bet_amount")){
          // if(msg.includes("api.not_found.platform_code")){
            console.log(<FormattedMessage id="alert.accountIncorrect"/>)
            Swal.fire({
              icon: "warning",
              // text: "下注金額低於或超過單筆限制",
              text:intl.formatMessage({
                id: 'alert.betAmountOut',
              }),
              showConfirmButton: true,
            });
          // }
        }
        else if(msg.includes("api.data_invalid.game_round_limit")){
          // if(msg.includes("api.not_found.platform_code")){
            console.log(<FormattedMessage id="alert.accountIncorrect"/>)
            Swal.fire({
              icon: "warning",
              // text: "單期下注金額超出總投注額",
              text:intl.formatMessage({
                id: 'alert.betLimit',
              }),
              showConfirmButton: true,
            });
          // }
        }
        break;
      case 401:
        if (msg.includes('api.unauthorized')) {
          Swal.fire({
            icon: "warning",
            // text: "請重新登入操作",
            text:intl.formatMessage({
              id: 'alert.logAgain',
            }),
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("access_token");
              window.location.href = "/";
            }
          });
        }
        break;
      case 403:
        if (msg.includes('api.forbidden')) {
          Swal.fire({
            icon: "warning",
            // text: "請重新登入操作，或此帳號尚無權限",
            text:intl.formatMessage({
              id: 'alert.accountPer',
            }),
            showConfirmButton: true,
          });
  
        }
        break;
      case 409:
        if (msg.includes('data_existent') && who === 'setPhone') {
          Swal.fire({
            icon: "warning",
            // text: "已驗證",
            text:intl.formatMessage({
              id: 'alert.verified',
            }),
            showConfirmButton: true,
          });
        } else if (msg.includes('data_existent') && who === 'sendVerificationMsg'){
          Swal.fire({
            icon: "warning",
            // text: "該號碼已存在",
            text:intl.formatMessage({
              id: 'alert.accountAlready',
            }),
        //     
            showConfirmButton: true,
          });
        }else{
          Swal.fire({
            icon: "warning",
            // text: "帳號已存在",
            text:intl.formatMessage({
              id: 'alert.accountAlready',
            }),
            showConfirmButton: true,
          });
        }
        break;
      case 500:
        Swal.fire({
          icon: "warning",
          // text: "伺服器錯誤請聯繫相關人員",
          text:intl.formatMessage({
            id: 'alert.serverPer',
          }),
          showConfirmButton: true,
        });
        break;
      default:
        break;
    }
  };

  //總換時區
  const TimeMoment = (value) =>{
    if(localStorage.getItem("country_code") == 'VN'){
      return moment(value).tz('Asia/Bangkok').format('DD/MM HH:mm')
    } else {
      return moment(value).tz('Asia/Chongqing').format('MM/DD HH:mm')
    }
  }

  const currencyJudge = () => {
    if(localStorage.getItem("currency") === 'CNY'){
      return ''
    }
      else {
      return 'K'
    }
  }

  return (
    <>
      {/* <select style={{'zIndex' : '999' , 'position' : "fixed" , "bottom" : '80px' , maxWidth : '80px'}}
            value={lang}
            onChange={(evt) => {
              setLang(evt.target.value);
              localStorage.setItem("language", evt.target.value);
            }}
          >
            <option value="en">English</option>
            <option value="cn">中文</option>
      </select> */}
      <ErrorContext.Provider value={{ ErrorMessage , TimeMoment , currencyJudge}}>
        <div className="app">
          {/* <button onClick={play}>播放音效</button> */}
          <BrowserRouter>
            {/* <Router> */}
            <ScrollToTop/>
              <Routes>
                <Route element={<Layout status={"2"}/> }>
                  <Route path="/index" element={<Home />} />
                  <Route path="/activity" element={<ActivityPage />} />
                  <Route path="/activity/:ActivityID" element={<ActivityDetail />} />
                  <Route path="*" element={<Navigate to="/index" replace />} />
                </Route>

                <Route element={<Layout /> }>
                  <Route path="/traders" element={<TradersPage />} />
                  <Route path="/joinTrader" element={<JoinTraderPage />} />
                  <Route path="/traderHome/:TrderID" element={<TraderHomePage />} />
                  <Route path="/traderMyHome" element={<TraderMyHomePage />} />
                  <Route path="/traderSet/:TrderID" element={<TraderSetPage />} />
                  <Route path="/trderRecord/:TrderID/:MirrorID" element={<TrderRecordPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/profile/profit" element={<Profit />} />
                </Route>

                <Route element={<GameLayout />}>
                  <Route path="/game/:GameID" element={<GamePage />} />
                  <Route path="/fourBall" element={<FourBall />} />
                  <Route path="/fourBallMa" element={<FourBallMa />} />
                  <Route path="/markSix" element={<MarkSix />} />
                  <Route path="/markSixMacao" element={<MarkSixMacao />} />
                  <Route path="/keno" element={<Keno />} />
                  <Route path="/vietnamLottery/:GameID" element={<VietnamLottery />} />
                  <Route path="/bingo/:GameID" element={<BingoPage />} />
                </Route>
                
                <Route path="/"  element={<LoginPage lang={lang} setLang={setLang} />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/password" element={<UpdatePwdPage />} />
                <Route path="/game" element={<GamePage />} />
              
              </Routes>
            {/* </Router> */}
          </BrowserRouter>
        </div>
      </ErrorContext.Provider>
    </>

  );
}

export default RoutesBox;
