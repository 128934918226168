import React , {useContext , useState} from 'react'
import './Profile.scss';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage , useIntl } from "react-intl";
import ThirdWalletsPop from '../../Component/All/ThirdWalletsPop'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Swal from 'sweetalert2';
import api from '../../api/api';

import { ErrorContext } from '../../Routes';



import avatarIcon from '../../images/my-agency-center/avatar.png';
import btn1 from '../../images/my-agency-center/recharge.svg';
import btn2 from '../../images/my-agency-center/withdraw-money.svg';
import btn3 from '../../images/my-agency-center/Invitation-code.svg';
import logoutBtn from '../../images/my-personal-center/Sign-out.png';


import { MdArrowDropUp } from 'react-icons/md';
import { ReactComponent as inviteCode } from '../../Component/Icon/inviteCode.svg'



const ProfileInfo = ({memberDetail , invitation , getMe , nowBalance , loadingBalance , credit}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { ErrorMessage , currencyJudge } = useContext(ErrorContext);

  const payOrder = async(e) => {
    e.preventDefault();
    var urlencoded = new URLSearchParams();
    urlencoded.append("redirect_url", window.location.href);
    try {
      const res = await api.post(`/pay-orders/launcher/pms`, urlencoded, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        // console.log(res.data)
        window.location.href = res.data.data
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'getPayOrderLauncher')
    }

  }
   // 登出func
  const handleLogout = async () => {
    try {
      const res = await api.delete('/logout', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          'x-platform-code': `${localStorage.getItem("x-platform-code")}`,
        },
      });
      if (res.data.status) {
        console.log(res.data.status)
        localStorage.removeItem("access_token");
        localStorage.removeItem("username");
        localStorage.removeItem("x-platform-code");
        Swal.fire({
          icon: "success",
          // text: "已登出",
          showConfirmButton: false,
          timer: 1000,
        });
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (err) {
      const errorCode = err.response.status;
      const errMsg = err.response.data.data.error;
      ErrorMessage(errorCode, errMsg);
    } finally {
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
      localStorage.removeItem("x-platform-code");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  };
  const UnbindAPI = async() => {
    try {
      const res = await api.post(`/member/tg/unbind`, {}, {
        headers: {
          "x-platform-code": `${localStorage.getItem("x-platform-code")}`,
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (res.data.status) {
        Swal.fire({
          icon: "issue",
          text: intl.formatMessage({
            id: 'alert.released',
          }),
        });
        
      }
    } catch (err) {
      console.log(err)
      const code = err.response.status
      const msg = err.response.data.data.error
      ErrorMessage(code, msg , 'getPayOrderLauncher')
    } finally {
      setTimeout(()=>{
        getMe()
      } , 2000)
      
    }

  }
  const Unbind = () =>{
  Swal.fire({
    text: intl.formatMessage({
      id: 'alert.whetherToUnbind',
    }),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: intl.formatMessage({
      id: 'game.confirm',
    }),
    cancelButtonText: intl.formatMessage({
      id: 'alert.cancel',
    }),
  }).then((result) => {
    if (result.isConfirmed) {
      UnbindAPI()
    }
  });
  }
  return (
    <>
      {
        open && 
        <ThirdWalletsPop
          open={open}
          setOpen={setOpen}
        />
      }
      <div className="userInfoWrap mt-4 d-flex ">
        <div className='avater-bx'>
          <div className='l'>
            <div className='avatar'>
              <img src={avatarIcon}></img>
            </div>
          </div>
          <div className='r'>
            <div className='d-flex nameBx'>
              <div className='userName' style={{fontSize : '14px'}}>
                <FormattedMessage
                    id={`MemberWrap.member`}
                ></FormattedMessage>
                <span>{memberDetail.response.account}</span></div>
              <button className='logoutBtn' onClick={handleLogout}>
                <img src={logoutBtn}></img>
                <div>
                  <FormattedMessage
                      id={`MemberWrap.signOut`}
                  ></FormattedMessage>
                </div>
              </button>
            </div>
            
            <div className='d-flex nameBx' style={{justifyContent:'flex-start' , fontSize : '16px'}}>
              <p className='mb-0' style={{marginRight : '8px'}}>
              <FormattedMessage
                    id={`MemberWrap.balance`}
              ></FormattedMessage> :</p>
              <p className='number mb-0'>
                {
                  loadingBalance || 
                  `${nowBalance} ${currencyJudge()}`
                }
              </p>
            </div>
            <div className='d-flex nameBx' style={{justifyContent:'flex-start' , fontSize : '16px'}}>
              <p className='mb-0' style={{marginRight : '8px'}}>
              <FormattedMessage
                    id={`profile.name`}
              ></FormattedMessage> :</p>
              <p className='number mb-0'>
                {
                memberDetail.response.name
                }
              </p>
            </div>
            <div className='d-flex nameBx' style={{justifyContent:'flex-start' , fontSize : '16px'}}>
              <p className='mb-0' style={{marginRight : '8px'}}>
              <FormattedMessage
                    id={`profile.phoneVer`}
              ></FormattedMessage>
                :</p>
              <p className='number mb-0'>
                {memberDetail.response.phone}
              </p>
            </div>
            {
              credit.status &&
              <div className='d-flex nameBx' style={{justifyContent:'flex-start' , fontSize : '16px'}}>
                <p className='mb-0' style={{marginRight : '8px'}}>
                  信用額度
                  :</p>
                <p className='number mb-0'>
                  {memberDetail.credit_balance}
                </p>
              </div>
            }
            
            <div className='d-flex nameBx' style={{justifyContent:'flex-start' , fontSize : '16px'}}>
              <p className='mb-0' style={{marginRight : '8px'}}>
              <FormattedMessage
                    id={`profile.messageBinding`}
              ></FormattedMessage>
                :</p>
              <p className='number mb-0'>
                {memberDetail.tg_status ? 
                <span className='green'>
                  {
                    memberDetail.tg_account !== '' &&
                    <i style={{fontStyle:'normal' , 'marginRight' : '5px'}}>{memberDetail.tg_account}</i>
                  }
                  <FormattedMessage
                        id={`profile.verified`}
                  ></FormattedMessage>
                  
                  <button onClick={Unbind}>
                    <FormattedMessage
                          id={`alert.Unbind`}
                    ></FormattedMessage>
                  </button>
                </span> 
                : 
                <span className='red'>
                  {
                    memberDetail.tg_account !== '' &&
                    <i style={{fontStyle:'normal' , 'marginRight' : '5px'}}>{memberDetail.tg_account}</i>
                  }
                  
                  <FormattedMessage
                        id={`profile.unverified`}
                  ></FormattedMessage>
                  {
                    memberDetail.tg_account !== '' &&
                    <button onClick={Unbind}>
                      <FormattedMessage
                          id={`alert.Unbind`}
                      ></FormattedMessage>
                    </button>
                  }
                  
                </span>}
              </p>
            </div>
          
          </div>
        </div>
      
        <div className="buttonsWrap d-flex">
          <button className='d-flex' onClick={(e)=>{
            payOrder(e)
          }}>
            <div className='imgBx'>
              <img src={btn1}></img>
            </div>
            <div className='ti'>
              <FormattedMessage
                        id={`nav.charge`}
              ></FormattedMessage>
            </div>
          </button>
          <button className='d-flex' onClick={(e)=>{
            setOpen(true)
          }}>
            <div className='imgBx'>
              <img src={btn2}></img>
            </div>
            <div className='ti'>
              <FormattedMessage
                        id={`profile.withdraw`}
              ></FormattedMessage>
            </div>
          </button>
          <button className='d-flex' onClick={(e)=>{
            const code = invitation.response.map((v)=>{
              if(v.member_id === memberDetail.response.id){
                return v.invitation_code
              }
            })
            Swal.fire({
                showCancelButton: false,
                confirmButtonText: intl.formatMessage({
                  id: 'alert.copy',
                }),
                text:`${intl.formatMessage({
                  id: 'profile.InvitationCode',
                })} : ${code}`
              }
              
            ).then((result) => {
              if (result.isConfirmed) {
                navigator.clipboard.writeText(`${window.location.origin}/register?${code}`)
                Swal.fire({
                  icon: 'success',
                  text: intl.formatMessage({
                    id: 'alert.success',
                  }),
                })

              }
            })
            
          }}>
            <div className='imgBx'>
              <img src={btn3}></img>
            </div>
            <div className='ti'>
              <FormattedMessage
                        id={`profile.InvitationCode`}
              ></FormattedMessage>
            </div>
          </button>
        </div>
      </div>
    </>
  )
}

export default ProfileInfo