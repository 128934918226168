import React, { useState, useEffect , useRef , useContext } from "react";
import { useSelector , useDispatch , Provider} from 'react-redux';
import { FormattedMessage , useIntl } from "react-intl";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../../Component/Game/GamePlace.scss';
import CloseIcon from '@mui/icons-material/Close';
import { event } from "jquery";
import { ErrorContext } from '../../Routes';

const ThirdWalletsPop = ({setOpen}) => {
    const { ErrorMessage } = useContext(ErrorContext);
    const dispatch = useDispatch();
    const getGameWallet = useSelector(state => state.game.gameWallet)
    const [transferOpen , setTransferOpen] = useState(false)
    const [selGameID , setSelGameID] = useState(0)
    const [amount , setAmoun] = useState(0)
    const [countdown, setCountdown] = useState(0);
    const [transferCountdown, setTransferCountdown] = useState(0);
    const [transferType , setTransferType] = useState(1)
    const getGameWalletAPI = () => {
      dispatch({ type: 'SAGA_FETCH_GAMEWALLET_API' });
    }
    const getGameWalletRefreshAPI = () => {
      dispatch({ type: 'SAGA_FETCH_GAMEWALLETREFRESH_API' });
    }
    const getGameWalletReturnAPI = () => {
      dispatch({ type: 'SAGA_FETCH_GAMEWALLETRETURN_API' , ErrorMessage });
      if (countdown === 0) {
        setCountdown(60);
      }
    }
    const submitTransfer = (e) =>{
      dispatch({ type: 'SAGA_FETCH_WALLETTRANSFER_API' , selGameID , transferType , amount , ErrorMessage});
      setTransferOpen(false)
      if (countdown === 0) {
        setTransferCountdown(15);
      }
    }
    const submitRecycle = (selGameID , transferType , amount ) =>{
      dispatch({ type: 'SAGA_FETCH_WALLETTRANSFER_API' , selGameID , transferType , amount});
      setTransferOpen(false)
      if (countdown === 0) {
        setTransferCountdown(15);
      }
    }
    const handleChange = (e) => {
      setSelGameID(e);
    };
    const handleAmount = (e) => {
      setAmoun(e.target.value);
    };
    useEffect(()=>{
        getGameWalletAPI()
    },[])
    useEffect(() => {
      if (countdown > 0) {
        const timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);
  
        return () => clearTimeout(timer);
      }
    }, [countdown]);
    useEffect(() => {
      if (transferCountdown > 0) {
        const timer = setTimeout(() => {
          setTransferCountdown(transferCountdown - 1);
        }, 1000);
  
        return () => clearTimeout(timer);
      }
    }, [transferCountdown]);
    return (
        <>
          {
            transferOpen &&
            <div className="walletEditPop">
              <div className="editBx">
                <div className='ruleClose'
                onClick={() => {
                    // handleBoxSwitch('rule');
                    setTransferOpen(false)
                  }}
                >
                <CloseIcon/>
                </div>
                <div className="inputWrap mb-3">
                  <label htmlFor="">轉帳類型</label>
                  <label className="radionBtn" htmlFor="on">
                    <input type="radio" name='status' id='on' defaultChecked
                      onChange={(e) => {
                        setTransferType(1)
                      }}
                    />
                    入款
                  </label>
                  <label className="radionBtn" htmlFor="off">
                    <input type="radio" name='status' id='off'
                      onChange={(e) => {
                        setTransferType(2)
                      }}
                    />
                    出款
                  </label>
                </div>
                {/* <div className="mb-3">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selGameID}
                    label="Age"
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>{'選擇遊戲'}</MenuItem>
                    {
                      getGameWallet.data.third_party_game_wallets.map((v)=>{
                        return(
                          <MenuItem value={v.third_party_game_id}>{v.third_party_game_name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </div> */}
                <div className="loginInput mb-3">
                  <input
                    type="text"
                    required
                    placeholder='輸入金額' 
                    onChange={(e)=>handleAmount(e)}
                    />
                </div>
                <button onClick={()=>submitTransfer()}>確認</button>
              </div>
            </div>
          }
          <div className='ruleWrap walletPop'>
              <div className='ruleDes'>
                  <div className='ruleClose'
                  onClick={() => {
                      // handleBoxSwitch('rule');
                      setOpen(false)
                    }}
                  >
                  <CloseIcon/>
                  </div>
                  <div className='ruleScrollBx' >
                    <div className="mainWallet">
                      <div>
                        <FormattedMessage
                            id={`wallets.mainWallet`}
                        />
                      </div>
                      {getGameWallet.data.balance}
                    </div>
                    <div className="botBx">
                      {
                        getGameWallet.data.third_party_game_wallets.map((v)=>{
                          return(
                            <div key={v.third_party_game_id}>
                              <div>
                                <div>{v.third_party_game_name}</div>
                                <div>
                                <FormattedMessage
                                    id={`wallets.amount`}
                                /> : {v.amount}</div>
                              </div>
                              {/* {
                                transferCountdown === 0 ?
                                <button 
                                  onClick={()=>{
                                    setTransferOpen(true)
                                    handleChange(v.third_party_game_id)
                                  }}
                                >
                                  轉帳
                                </button>
                                :
                                <button>{transferCountdown}s</button>
                              } */}
                              {
                                transferCountdown === 0 ?
                                <button 
                                  onClick={()=>{
                                    // setTransferOpen(true)
                                    // handleChange(v.third_party_game_id)
                                    submitRecycle(v.third_party_game_id , 2 , v.amount)
                                  }}
                                >
                                  <FormattedMessage
                                      id={`wallets.recycle`}
                                  />
                                </button>
                                :
                                <button>{transferCountdown}s</button>
                              }
                            </div>
                          )
                        })
                      }
                      
                    </div>
                    <div className="buttonBx">
                      <div>
                        <button onClick={()=>getGameWalletRefreshAPI()}>
                          <FormattedMessage
                              id={`wallets.refresh`}
                          />
                        </button>
                      </div>
                      <div>
                        {
                          countdown === 0 ? 
                          <button onClick={()=>getGameWalletReturnAPI()}>
                            <FormattedMessage
                                id={`wallets.OkRecycling`}
                            />
                          </button>
                          :
                          <>
                           <button>
                            {countdown}s
                           </button>
                          </>
                        }
                       
                      </div>
                    </div>
                      
                  </div>
                  
              </div>
          </div>
        </>
      )
}


export default ThirdWalletsPop
